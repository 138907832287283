import React from 'react'
import Helmet from 'react-helmet'
import Waypoint from 'react-waypoint'
import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/Header'
import Nav from '../components/Nav'
import pic01 from  '../assets/images/react-themes-stellar.jpg'

import json_data from '../assets/data.json'


class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    }
  }

  _handleWaypointEnter= () => {
    this.setState(() => ({ stickyNav: false }));
  }

  _handleWaypointLeave = () => {
    this.setState(() => ({ stickyNav: true }));
  }

  render() {

    return (
      <Layout>
        <Helmet>
            <title>React Themes - Stellar</title>
            <meta name="description" content="React App, designed by HTML5 Up, Enhanced by @AppSeed" />
        </Helmet>

        <Header />

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav} />

        <div id="main">

          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>{json_data['title-1']}</h2>
                </header>
                <p>
                    
                  {json_data['info-1']} 
                  <br />
                  <br />Page assets loaded from <Link to="/data.json">json file</Link>
                  <br /><a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.org">GatsbyJS</a>{' - State of the art React framework '}
                  <br /><a target="_blank" rel="noopener noreferrer" href="https://www.npmjs.com/package/ftp-deploy">Ftp-Deploy</a>{' - npm module to handle the automatic deploy '} 
                  <br /><a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.org/packages/gatsby-plugin-google-analytics/">Analytics Plugin</a>{' - GatsbyJS plugin'}
                  <br /><a target="_blank" rel="noopener noreferrer" href="https://www.gatsbyjs.org/packages/gatsby-plugin-sitemap/">Sitemap Plugin</a>{' - GatsbyJS plugin'}
                </p>
                <ul className="actions">
                  <li>
                    <a target="_blank" without rel="noopener noreferrer" href="https://blog.appseed.us/react-themes-dashboards-landing-pages" className="button">Learn More</a>
                  </li>
                </ul>
              </div>
              <span className="image"><img src={pic01} alt="React Themes Stellar" /></span>
            </div>
          </section>

          <section id="first" className="main special">
            <header className="major">
              <h2>Section 1</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-code"></span>
                <h3>Coded in React</h3>
                <p>Modern Stack coded in React / GatsbyJS. Enhanced by AppSeed with: Google Analytics, Sitemap, FTP Deploy Script</p>
              </li>
              <li>
                <span className="icon major style3 fa-copy"></span>
                <h3>Open-Source</h3>
                <p>
                  The Source Code can be downloaded and used directly in your private or commercial projects.
                </p>
              </li>
              <li>
                <span className="icon major style5 fa-diamond"></span>
                <h3>HTML5 Up Design</h3>
                <p>State of the art design, modular and easy to customize. Released under permissive license.</p>
              </li>
            </ul>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a target="_blank" without rel="noopener noreferrer" href="https://blog.appseed.us/react-themes-dashboards-landing-pages" className="button">Learn More</a>
                </li>
              </ul>
            </footer>
          </section>

          <section id="second" className="main special">
            <header className="major">
              <h2>Section 2</h2>
              <p>Donec imperdiet consequat consequat. Suspendisse feugiat congue<br />
              posuere. Nulla massa urna, fermentum eget quam aliquet.</p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-code-fork"></span>
                <strong>5,120</strong> Etiam
              </li>
              <li className="style2">
                <span className="icon fa-folder-open-o"></span>
                <strong>8,192</strong> Magna
              </li>
              <li className="style3">
                <span className="icon fa-signal"></span>
                <strong>2,048</strong> Tempus
              </li>
              <li className="style4">
                <span className="icon fa-laptop"></span>
                <strong>4,096</strong> Aliquam
              </li>
              <li className="style5">
                <span className="icon fa-diamond"></span>
                <strong>1,024</strong> Nullam
              </li>
            </ul>
            <p className="content">Nam elementum nisl et mi a commodo porttitor. Morbi sit amet nisl eu arcu faucibus hendrerit vel a risus. Nam a orci mi, elementum ac arcu sit amet, fermentum pellentesque et purus. Integer maximus varius lorem, sed convallis diam accumsan sed. Etiam porttitor placerat sapien, sed eleifend a enim pulvinar faucibus semper quis ut arcu. Ut non nisl a mollis est efficitur vestibulum. Integer eget purus nec nulla mattis et accumsan ut magna libero. Morbi auctor iaculis porttitor. Sed ut magna ac risus et hendrerit scelerisque. Praesent eleifend lacus in lectus aliquam porta. Cras eu ornare dui curabitur lacinia.</p>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a target="_blank" without rel="noopener noreferrer" href="https://blog.appseed.us/react-themes-dashboards-landing-pages" className="button">Learn More</a>
                </li>
              </ul>
            </footer>
          </section>

          <section id="cta" className="main special">
            <header className="major">
              <h2>About AppSeed</h2>
              <p>
              AppSeed is a <strong>FullStack Web App Generator</strong> that allows you to choose a visual theme and apply it on a full, but flexible, technology stack in just a few minutes, greatly improving development time from visual design to application deployment.               
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li>
                  <a href="https://appseed.us" className="button special">Build React Apps</a> {' '}
                  <a href="https://blog.appseed.us/react-themes-free-paid-material-kit/" className="button">More React Themes</a>
                </li>
              </ul>
            </footer>
          </section>

        </div>

      </Layout>
    )
  }
}

export default Index
