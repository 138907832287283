import React from 'react'
import { Link } from 'gatsby'

import logo from '../assets/images/logo.svg';

const Footer = (props) => (
    <footer id="footer">
        <section>
            <h2>React Themes - Stellar</h2>
            <p>Sed lorem ipsum dolor sit amet et nullam consequat feugiat consequat magna adipiscing tempus etiam dolore veroeros. eget dapibus mauris. Cras aliquet, nisl ut viverra sollicitudin, ligula erat egestas velit, vitae tincidunt odio.</p>
            <ul className="actions">
                <li>
                <a href="https://blog.appseed.us/react-themes-stellar-design-html5up" className="button">Learn More</a>
                </li>
            </ul>
        </section>
        <section>
            <h2>Etiam feugiat</h2>
            <dl className="alt">
                <dt>Address</dt>
                <dd>1234 Somewhere Road &bull; Nashville, TN 00000 &bull; USA</dd>
                <dt>Phone</dt>
                <dd>(000) 000-0000 x 0000</dd>
                <dt>Email</dt>
                <dd><a href="#">information@untitled.tld</a></dd>
            </dl>
            <ul className="icons">
                <li><a target="_blank" href="https://github.com/react-theme/stellar" className="icon fa-github alt"><span className="label">GitHub</span></a></li>
                <li><a target="_blank" href="https://twitter.com/webappseed" className="icon fa-twitter alt"><span className="label">Twitter</span></a></li>
                <li><a target="_blank" href="https://facebook.com/webappseed" className="icon fa-facebook alt"><span className="label">Facebook</span></a></li>
                <li><a target="_blank" href="https://instagram.com/webappseed" className="icon fa-instagram alt"><span className="label">Instagram</span></a></li>
            </ul>
        </section>
        <p className="copyright">&copy; AppSeed. Design: <a href="https://html5up.net">HTML5 UP</a>. 
        Built with <a href="https://appseed.us">FullStack Web App Generator</a></p>
    </footer>
)

export default Footer
