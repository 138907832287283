import React from 'react'

import logo from '../assets/images/logo.svg';

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="React Themes - Stellar by @AppSeed.us" /></span>
        <h1>React Themes - Stellar</h1>
        <p>Designed by HTML5 Up, Enhanced by AppSeed<br />
        PWA App, {' '}
        <a target="_blank" rel="noopener noreferrer" href="https://developers.google.com/speed/pagespeed/insights/?url=https://react-themes-stellar.appseed.us">Fast As Hell</a>, {' '}
        FTP deploy script, {' '}
        <a target="_blank" rel="noopener noreferrer" href="https://github.com/react-theme/stellar">Github Sources</a> {' '}
        </p>
    </header>
)

export default Header
